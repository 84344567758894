<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <div class="vx-row">
                    <div class="vx-col md:w-1/2 mt-2">
                        <label class="vs-input--label">Loại bài viết</label>
                        <v-select class="w-full" v-model="articleTypeModel" :clearable="false"
                                  :options="articleTypeOptions"
                                  data-vv-validate-on="blur" v-validate="'required'" name="leaveType"/>
                        <span class="text-danger text-sm">{{ errors.first('leaveType') }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-2 ">
                        <label class="vs-input--label">Phân quyền xem</label>
                        <v-select class="w-full" v-model="authorizationTypeModel" :clearable="false"
                                  :options="authorizationTypeOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" name="leaveStatus"
                                  v-validate="'required'"/>
                        <span class="text-danger text-sm">{{ errors.first('leaveStatusOptions') }}</span>
                    </div>
                </div>

                <div class="vx-row" v-if="isShowSelected">
                    <div class="vx-col md:w-1/2 mt-2 ">
                        <label class="vs-input--label">Chi nhánh</label>
                        <v-select class="" v-model="branchModel" :clearable="false" :options="branchOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                  name="branch">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                    </div>

                    <div class="vx-col md:w-1/2 mt-2">
                        <label class="vs-input--label">Phòng ban</label>
                        <v-select :clearable="false" :disabled="isUpdate" v-validate="'required'"
                                  placeholder="Chọn phòng ban"
                                  v-model="departmentModel"
                                  :options="departmentOptions">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('leaveStatusOptions') }}</span>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="vx-col mt-2">
                        <vs-input class="w-full" label="Tiêu đề bài viết" placeholder="Nhập tiêu đề"
                                  v-model="newsInfo.title"
                                  :disabled="isUpdate" v-validate="'required'" name="title"/>
                        <span class="text-danger text-sm">{{ errors.first('title') }}</span>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col w-full mt-5 mb-4 quill-editor">
                        <label class="vs-input--label">Nội dung bài viết</label>
                        <quill-editor class="input-content" v-model="newsInfo.content"></quill-editor>
                    </div>

                </div>

                <br>
                <br>

                <div class="vx-row">
                    <div class="vx-col md:w-1/2 w-full mt-16" v-if="isShowOption">
                        <label>Nhiều lựa chọn</label>
                        <vs-switch v-model="newsInfo.multiChoiceType"/>
                        <div :key="index" class="vx-row mt-5" v-for="(item, index) in articleOptions">
                            <div class="vx-col md:w-1/2 w-full">
                                <vs-input class="w-full" placeholder="Lựa chọn" v-model="item.name"
                                          name="option-choose"/>
                                <span class="text-danger text-sm">{{ errors.first('optionChoose') }}</span>
                            </div>
                            <div class="vx-col mt-2" v-if="articleOptions.length > 1">
                                <feather-icon icon="MinusCircleIcon"
                                              svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                                              v-if="!isUpdate"
                                              @click="confirmDeleteRecord(index)"/>
                            </div>
                        </div>

                        <div class="vx-col md:w-full w-1/3 mt-5 btn-choose" v-if="!isUpdate"
                             @click="addNewRecord(index + 0)">
                            <label>Thêm lựa chọn</label>
                            <feather-icon icon="" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"/>
                        </div>
                    </div>
                </div>

                <br>

                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full">
                        <label class="custom-file-input" for="Upload">
                        </label>
                        <input id="Upload" class="mb-4" type="file" :data="acceptData" @change="uploadFieldChange"
                               multiple
                               style="visibility: hidden">
                        <div class="mt-2" :key="file" v-for="(item, file) in filePostDTOS">
                            <a :href="item.url">{{ item.name }}</a>
                            <vx-tooltip class="position-icon mr-2" text="Xóa" position="top">
                                <feather-icon icon="TrashIcon" class="action-err icon-btn"
                                              svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                                              @click="confirmDeleteFiles(item)"/>
                            </vx-tooltip>
                        </div>
                    </div>
                </div>


                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                            <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                    `${isUpdate ?
                                        'Cập nhật' : 'Tạo mới'}`
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="leaveList">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import utils from "../../../components/utils";
import moment from 'moment';
import 'quill/dist/quill.snow.css';
import {quillEditor} from 'vue-quill-editor'


export default {
    components: {
        vSelect,
        Datepicker,
        moment,
        utils,
        quillEditor,

    },
    data() {
        return {
            content: '',
            nameImg: [],
            acceptData: '',
            dataFile: null,
            index: null,
            branchOptions: [],
            departmentOptions: [],
            isShowOption: false,
            isShowSelected: false,
            optionChoose: null,
            isUpdate: false,
            articleTypeOptions: [
                {value: 0, label: 'Bài viết'},
                {value: 1, label: 'Đánh giá'},
                {value: 2, label: 'Thăm dò ý kiến'},

            ],
            authorizationTypeOptions: [
                {value: 0, label: 'Tất cả'},
                {value: 1, label: 'Giới hạn phòng ban'},
            ],
            articleOptions: [{}],
            uploadPercent: 0,
            isShowPercent: false,
            newsInfo: {},
            filePostDTOS: [],
            file: [],
        }
    },

    created() {
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        this.branchOptions = branchList.map(x => {
            return {label: x.organizationBranchName, value: x.id}
        });
        this.newsInfo = {
            articleType: 0,
            authorizationType: 0,
            content: "",
            title: "",
            departmentId: null,
            filePostDTOS: [],
            multiChoiceType: 0,
            optionResponseDTOS: [],
            organizationBranchId: this.$route.query.branch_id,
        };

        let postId = this.$route.query.postId;
        if (postId) {
            this.findById();
        }
    },
    computed: {
        branchModel: {
            get() {
                return {
                    label: this.getLabelString(this.branchOptions.find(x => x.value == this.newsInfo.organizationBranchId)),
                    value: this.newsInfo.organizationBranchId
                }
            },
            set(obj) {
                this.newsInfo.organizationBranchId = obj.value
                this.listDepartment()
            }
        },
        departmentModel: {
            get() {
                return {
                    label: this.getLabelString(this.departmentOptions.find(x => x.value === this.newsInfo.departmentId)),
                    value: this.newsInfo.departmentId
                }
            },
            set(obj) {
                this.newsInfo.departmentId = obj.value;
                if (this.newsInfo.departmentId) {
                    this.isShowManagerDepartment = true;
                } else {
                    this.isShowManagerDepartment = false;
                }
            }
        },
        articleTypeModel: {
            get() {
                return {
                    label: this.getLabelString(this.articleTypeOptions.find(x => x.value == this.newsInfo.articleType)),
                    value: this.newsInfo.articleType
                }
            },
            set(obj) {
                this.newsInfo.articleType = obj.value;
                if (obj && obj.value == 2) {
                    this.isShowOption = true
                } else {
                    this.isShowOption = false
                }
            }
        },
        authorizationTypeModel: {
            get() {
                return {
                    label: this.getLabelString(this.authorizationTypeOptions.find(x => x.value == this.newsInfo.authorizationType)),
                    value: this.newsInfo.authorizationType
                }
            },
            set(obj) {
                this.newsInfo.authorizationType = obj.value;
                if (obj && obj.value == 1) {
                    this.isShowSelected = true
                } else {
                    this.isShowSelected = false
                }
            }
        },
        validateForm() {
            return !this.errors.any()
        },
    },

    methods: {
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        findById() {
            this.$vs.loading();
            this.$crm.get('/posts/find-by-id?postId=' + this.$route.query.postId)
                .then((response) => {
                    let resFilePostDTOS = response
                    if (response.data) {
                        this.isUpdate = true;
                        this.newsInfo = response.data;
                        this.articleTypeModel = this.articleTypeOptions[response.data.articleType]
                        this.articleOptions = [];
                        response.data.optionResponseDTOS.forEach(x => {
                            this.articleOptions.push({name: x.name});
                        });
                        this.filePostDTOS = resFilePostDTOS.data.filePosts;
                    }
                    this.$vs.loading.close();
                })
                .catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
        },
        listDepartment() {
            let searchInfo = {
                organizationBranchId: this.newsInfo.organizationBranchId
            }
            this.$crm.post('/department/find-by-condition', searchInfo).then((response) => {
                this.departmentOptions = response.data.map(x => {
                    return {
                        value: x.id,
                        label: x.departmentName
                    }
                });
                this.departmentOptions.unshift({value: null, label: 'Không xác định'});
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;
                const result = await this.$validator.validateAll();
                if (!result) return;
                await this.handleClickUploadFile();
                this.newsInfo.filePostDTOS = this.filePostDTOS;
                let url = this.isUpdate ? `/posts/update?postId=` + this.$route.query.postId : `/posts/create`,
                    data = this.newsInfo
                data.optionAddDTOS = this.articleOptions
                if (data.multiChoiceType) {
                    data.multiChoiceType = 1
                } else {
                    data.multiChoiceType = 0
                }
                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.$vs.loading.close();
                    this.leaveList();
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} thành công`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },

        handleClickUploadFile() {
            return new Promise((resolve) => {
                let formData = new FormData(), config = {};
                let isUploadFile = 0;
                for (let i = 0; i < this.filePostDTOS.length; i++) {
                    let file = this.filePostDTOS[i];
                    if (!file.id) {
                        isUploadFile++;
                        formData.append('files', this.filePostDTOS[i]);
                    }
                }
                if (isUploadFile > 0) {
                    config = {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        },
                    };
                    this.$vs.loading();
                    this.$crm.post('/posts/file/upload', formData, config).then((res) => {
                        res.data.forEach(file => {
                            this.filePostDTOS = this.filePostDTOS.filter(f => f.name != file.name);
                        });
                        this.filePostDTOS.push(...res.data);
                        resolve(true);
                    }).catch((err) => {
                        this.$vs.loading.close();
                        return this.$vs.notify({
                            text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    });
                } else {
                    resolve(true);
                }
            });
        },

        uploadFieldChange(event) {
            this.filePostDTOS.push(...event.target.files);
            this.filePostDTOS = [...new Map(this.filePostDTOS.map(item =>
                [item['name'], item])).values()];
        },

        confirmDeleteFiles(file) {
            this.filePostDTOS = this.filePostDTOS.filter(x => x.name != file.name);
        },

        addNewRecord(index) {
            this.articleOptions.splice(index, 0, {})
        },
        confirmDeleteRecord(index) {
            this.selectedOptionsId = index;
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận xoá`,
                text: `Bạn có chắc là muốn xoá`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },
        deleteRecord() {
            this.articleOptions.splice(this.selectedOptionsId, 1)
        },

        leaveList() {
            this.$router.push(`/user/manage-News${this.newsInfo.organizationBranchId ? `?branch_id=${this.newsInfo.organizationBranchId}` : ''}`).catch(() => {
            })
        }
    },
}
</script>

<style scoped>
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'Chọn file';
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid rgb(5, 152, 98);
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}

.custom-file-input:hover::before {
    border-color: rgb(5, 152, 98);
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, rgb(5, 152, 98), rgb(5, 152, 98));
}

.btn-choose {
    width: 48% !important;
    text-align: center;
    background-color: white;
    justify-content: center;
    border-radius: 5px;
    border: 2px solid #ccc;
}

.btn-choose:hover {
    color: rgba(var(--vs-primary),1);
    background-color: white;
    border-color: rgba(var(--vs-primary),1);
}

#Upload {
    margin-top: 70px;
}

.input-content {
    height: 200px !important;
}

</style>
